<template>
  <main>
    <vuetable
      ref="vuetable"
      api-url="https://vuetable.ratiw.net/api/users"
      :fields="['id', 'Дата', 'Автор', 'Текст', 'Рейтинг', 'Действия']"
      data-path=""
      pagination-path=""
    ></vuetable>
  </main>
</template>

<script>
import Vuetable from "vuetable-2";

export default {
  components: {
    Vuetable,
  },
  name: "ReviewsModeration",
  created: function () {
    this.getReviewsList();
  },
  data: function () {
    return {
      processing: false,
      isDataLoaded: true, //@@@ spinnder, make it false before api calls
      reviewsList: [],
    };
  },
  methods: {
    getReviewsList: function () {
      // @@@ get from api
      this.reviewsList = [
        {
          id: 11,
          author: "Дима",
          text: "Очень длиннннннннннн  нннннннннннн нннннный отзыв!",
          rating: 5,
          date: 1624966889,
          isApproved: false,
        },
        {
          id: 12,
          author: "badboy777",
          text: "не отзыв!",
          rating: 3,
          date: 1614263889,
          isApproved: false,
        },
        {
          id: 13,
          author: "zxc",
          text: "123!",
          rating: 3,
          date: 1614169889,
          isApproved: false,
        },
        {
          id: 34,
          author: "bbbbbb",
          text: "!!!!none!",
          rating: 3,
          date: 1614169889,
          isApproved: false,
        },
      ];
    },
    deleteReview: function (review_id, index) {
      // @@@ remove index
      // @@@ find indexOf id===review_id
      console.log(index);
      this.reviewsList.splice(index, 1);
    },
    parseDateToRuFormat: function (date) {
      return new Intl.DateTimeFormat("ru-RU", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(date * 1000);
    },
  },
};
</script>

<style scoped></style>
